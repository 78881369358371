<template>
	<!-- 邀请好友 -->
	<div class="container">
		<template v-if="isMobile==0">
			<div class="header">
				<span>{{$t('aside.list')[10]}}</span>
			</div>
			
			<div class="content invite_box">
				<div class="row-top">
					<div class="row-left">
						<div class="invite_form">
							<div class="item">
								<div class="title">{{$t('invite.code')}}</div>
								<div class="text">
									<input v-model="code" type="text" class="inp" readonly />
									<span class="g_btn btn copyCode" @click="handleCopyCode">{{$t('common.copy')}}</span>
								</div>
							</div>
							<div class="item">
								<div class="title">{{$t('invite.link')}}</div>
								<div class="text">
									<input v-model="invite_url" type="text" class="inp" readonly />
									<span class="g_btn btn copyUrl" @click="handleCopyUrl">{{$t('common.copy')}}</span>
								</div>
							</div>
							<div class="tips">
								<p>{{$t('invite.rules')}}</p>
								<p>1. {{$t('invite.rules1')}}</p>
								<p>2. {{$t('invite.rules2')}}</p>
							</div>
						</div>
					</div>
					<div class="row-right">
						<div class="invite_info">
							<div class="box">
								<div class="item big">
									<div class="number">{{reward}}</div>
									<p>{{$t('invite.revenue')}}(USDT)</p>
								</div>
								<div class="item">
									<div class="number">{{today_num}}</div>
									<p>{{$t('invite.today')}}</p>
								</div>
								<div class="item">
									<div class="number">{{total_num}}</div>
									<p>{{$t('invite.total')}}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="row-bottom">
					<div class="invite_record">
						<div class="head">{{$t('invite.record')}}</div>
						<div class="list">
							<div class="hd">
								<div class="account">{{$t('invite.account')}}</div>
								<div class="dollar">{{$t('invite.money')}}</div>
								<div class="time">{{$t('invite.pay')}}</div>
							</div>
							<div class="bd">
								<div class="bd_box">
									<div class="item" v-for="(item,index) in inviteList" :key="index">
										<div class="account">{{item.account}}</div>
										<div class="dollar">{{item.num}} USDT</div>
										<div class="time">{{item.time}}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</template>
		<template v-else-if="isMobile==1">
			<div class="content_phone">
				<div class="invite_form_box">
					<div class="item">
						<div class="title">{{$t('invite.code')}}</div>
						<div class="text">
							<input v-model="code" type="text" class="inp" readonly />
							<span class="g_btn btn copyCode" @click="handleCopyCode">{{$t('common.copy')}}</span>
						</div>
					</div>
					<div class="item">
						<div class="title">{{$t('invite.link')}}</div>
						<div class="text">
							<input v-model="invite_url" type="text" class="inp" readonly />
							<span class="g_btn btn copyUrl" @click="handleCopyUrl">{{$t('common.copy')}}</span>
						</div>
					</div>
					<div class="data">
						<div class="row">
							<p>{{$t('invite.revenue')}}</p>
							<div class="number">{{reward}} USDT</div>
						</div>
						<div class="row">
							<p>{{$t('invite.today')}}</p>
							<div class="number">{{today_num}}</div>
						</div>
						<div class="row">
							<p>{{$t('invite.total')}}</p>
							<div class="number">{{total_num}}</div>
						</div>
					</div>
				</div>
				
				<div class="tips">
					<p>{{$t('invite.rules')}}</p>
					<p>1. {{$t('invite.rules1')}}</p>
					<p>2. {{$t('invite.rules2')}}</p>
				</div>
			</div>
		</template>
	</div>
</template>

<style scoped="scoped" lang="stylus">
	@import 'invite.styl';
</style>

<script>
	import { Loading } from 'element-ui';
			
	import Clipboard from 'clipboard';
	// api
	import {getInvite} from '@/api/invite'

	import { mapGetters } from 'vuex';
	export default ({
		name: 'invite',
		data() {
			return {
				inviteList:[],
				reward:'',
				today_num:'',
				total_num:'',
			}
		},
		created(){
			const loading = Loading.service({
				target: '.main',
				text: 'Loading',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)',
				customClass: 'apploading',
			});
			getInvite({account:this.account}).then(res=>{
				this.inviteList=res.list;
				this.reward=res.reward;
				this.total_num=res.total_num;
				this.today_num=res.today_num;

				loading.close();
			}).catch(err=>{
				loading.close();
			})
		},
		computed:{
			...mapGetters(['account','code','invite_url','isMobile']),
		},
		methods: {
			handleCopyCode() {
				let code = this.code;
				let clipboard = new Clipboard('.copyCode', {
					text: () => {
						return code
					}
				})
				clipboard.on('success', () => {
					this.$message({
						message: this.$t('tip.copysuccess'),
						type: 'success',
						center: true,
						customClass: 'shotMsg',
					});
					clipboard.destroy();
				})
				clipboard.on('error', () => {
					this.$message({
						message: this.$t('tip.copyerr'),
						type: 'error',
						center: true,
						customClass: 'shotMsg',
					});
					clipboard.destroy();
				})
			},
			handleCopyUrl() {
				let code = this.invite_url;
				let clipboard = new Clipboard('.copyUrl', {
					text: () => {
						return code
					}
				})
				clipboard.on('success', () => {
					this.$message({
						message:  this.$t('tip.copysuccess'),
						type: 'success',
						center: true,
						offset: 100,
						customClass: 'shotMsg',
					});
					clipboard.destroy();
				})
				clipboard.on('error', () => {
					this.$message({
						message: this.$t('tip.copyerr'),
						type: 'error',
						center: true,
						offset: 100,
						customClass: 'shotMsg',
					});
					clipboard.destroy();
				})
			},
		}
	})
</script>
